<template>
  <div class="">
    <Modal :ref="id" :id="id" size="lg" :title="title">
      <div class="">
        <div class="w-100 px-3">
          <form @submit.prevent.stop="submitForm" autocomplete="false">
            <div class="form-div">
              <div
                v-for="(fieldValue, fieldName) in formFields"
                :key="fieldName"
              >
                <div class="w-100 pr-3 py-2">
                  <label
                    :for="fieldName"
                    class="d-flex mx-2 text-uppercase gap">
                    {{ fieldName.replaceAll("_", " ") }}
                    <span class="text-danger text-lowercase"
                      >{{ requiredFields.includes(fieldName) ? "*" : " "
                      }}{{ errors[fieldName] }}</span
                    >
                  </label>
                  <div
                    v-if="isEditSenderLoading"
                    class="integrationImgae bg-preload"
                    style="height: 50px; width: 100%; border-radius: 14px"
                  >
                    <div class="into-preload"></div>
                  </div>
                  <input
                    v-else
                    :type="emailFields.includes(fieldName) ? 'email' : 'text'"
                    v-model="formData[fieldName]"
                    :id="fieldName"
                    class="mx-2 px-2 w-100 quizell-form-control"
                    :placeholder="'Enter ' + fieldName.replaceAll('_', ' ')"
                  />
                </div>
              </div>
            </div>
            <div class="text-center mt-4">
              <Button :is-loading="isLoadingBtn || isEditSenderLoading"
                >{{ senderFormMode == "create" ? "Create" : "Update" }}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import Button from "../../Layout/Button.vue";
import Modal from "../../Layout/Modal.vue";
import axios from "axios";
export default {
  props: {
    senderFormMode: String,
  },
  data() {
    return {
      windowOpen:null,
      errors: {},
      isLoadingBtn: false,
      isEditSenderLoading: false,
      requiredFields: [
        "from_email",
        "from_name",
        "reply_to_email",
        "reply_to_name",
        "address",
        "country",
      ],
      emailFields: ["from_email", "reply_to_email"],
      formFields: {
        nick_name: "",
        from_email: "",
        from_name: "",
        reply_to_email: "",
        reply_to_name: "",
        address: "",
        city: "",
        state: "",
        zip: "",
        country: "",
      },
      formData: {
        nick_name: "",
        from_email: "",
        from_name: "",
        reply_to_email: "",
        reply_to_name: "",
        address: "",
        city: "",
        state: "",
        zip: "",
        country: "",
      },
    };
  },
  components: {
    Modal,
    Button,
  },
  computed: {
    title() {
      let title = this.senderFormMode == "create" ? "Create" : "Update";
      return title + " Sender Email";
    },
    id() {
      return this.senderFormMode == "create"
        ? "CreateSenderEmailModal1"
        : "UpdateSenderEmailModal1";
    },
  },
  methods: {
    open(id = "") {
      this.errors = {};
      id;
      this.$refs[this.id].open();
      if (this.senderFormMode == "create") {
        this.isEditSenderLoading = false;
        this.requiredFields = [
          "from_email",
          "from_name",
          "reply_to_email",
          "reply_to_name",
          "address",
          "country",
        ];
        this.emailFields = ["from_email", "reply_to_email"];
        this.formFields = {
          nick_name: "",
          from_email: "",
          from_name: "",
          reply_to_email: "",
          reply_to_name: "",
          address: "",
          city: "",
          state: "",
          zip: "",
          country: "",
        };
        this.formData = {
          nick_name: "",
          from_email: "",
          from_name: "",
          reply_to_email: "",
          reply_to_name: "",
          address: "",
          city: "",
          state: "",
          zip: "",
          country: "",
        };
        this.$refs[this.id].open();
      } else {
        this.$refs[this.id].open();
        this.requiredFields = [
          "from_name",
          "reply_to_email",
          "reply_to_name",
          "address",
          "country",
        ];
        this.emailFields = ["reply_to_email"];

        (this.formFields = {
          nick_name: "",
          from_name: "",
          reply_to_email: "",
          reply_to_name: "",
          address: "",
          city: "",
          state: "",
          zip: "",
          country: "",
        }),
          this.$refs[this.id].open();
        this.getSenderDetail(id);
      }
    },
    async getSenderDetail(id) {
      this.isEditSenderLoading = true;
      let apiInfo = {
        axiosMethod: "get",
        resource: "verified_senders",
        method: "edit",
        id: id,
      };
      const response = await this.$store.dispatch("fetchData", apiInfo);
      if (response && response.status == "success") {
        this.formData = response.data;
      } else {
        //
      }
      this.isEditSenderLoading = false;
    },
    validateForm() {
      this.errors = {};
      for (const fieldName of this.requiredFields) {
        if (!this.formData[fieldName]) {
          this.errors[fieldName] = "required";
        }
      }
      const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

      for (const fieldName of this.emailFields) {
        if (
          this.formData[fieldName] &&
          !emailRegex.test(this.formData[fieldName])
        ) {
          this.errors[fieldName] = "Invalid email format.";
        }
      }
      return Object.keys(this.errors).length === 0;
    },

    async submitForm() {
      try {
        this.isLoadingBtn = true;
        if (this.validateForm()) {
          // let apiInfo = {
          //   axiosMethod: "post",
          //   resource: "verified_senders",
          //   method: "save",
          //   params: this.formData,
          // };
          // if(this.senderFormMode=='update')
          // {
          //   apiInfo.axiosMethod='put',
          //   apiInfo.id=this.formData.id
          // }
          if (this.senderFormMode == "update") {
            const response = await axios.put(
              `/api/v1/email-senders/${this.formData.id}`,
              this.formData
            );

            if (response && response.data.status == "success") {
              this.toast("Sender created successfully");
              this.$emit("reload");
              this.$refs[this.id].close();
            } else {
              this.toast("Something went wrong");
            }
            return true
          }

          const response = await axios.post(
            `/api/v1/email-senders`,
            this.formData
          );
          if (response && response.data.status == "success") {
            this.toast("Sender created successfully");
            this.$emit("reload");
            this.$refs[this.id].close();
            if(this.windowOpen)
          {
            this.performAction()
          }
          } else {
            this.toast("Something went wrong");
          }
        }
      } catch (error) {
        if (error && error.response.data.errors) {
          const validationErrors = error.response.data.errors;
          validationErrors.forEach((errorMessage) => {
                this.toast(errorMessage.message);
              });

        }
      } finally {
        this.isLoadingBtn = false;
       
      }
    },
    performAction() {
      window.opener.postMessage('close', '*');
      window.close();
    },
    toast(text) {
      this.$toasted.show(text, {
        theme: "toasted-primary",
        position: "bottom-center",
        duration: 2000,
      });
    },
  },
  mounted(){
    this.windowOpen = this.$route.query.return||false
  }
};
</script>

<style scoped>
.quizell-form-control {
  height: 48px;
  border-radius: 10px;
  border: 1px solid #d2d8e0;
  border-radius: 8px;
  outline: none;
  box-shadow: none;
  transition: 0.3s ease all;
  background-color: transparent;
}
.quizell-form-control:focus-visible {
  border: none !important;
  /* outline: 1px solid #4d1b7e; */
  box-shadow: 0 0 0 0.1rem #4d1b7e;
}
label {
  color: rgba(115, 115, 141, 1);
  font-size: 12px;
}
.form-div {
  height: 400px;
  overflow-x: scroll;
}
</style>
